<template>
  <v-card flat height="100%" class="rounded-lg pa-2">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div class="d-flex">
        <v-tabs left>
          <v-tab
            v-for="(time, key) in times"
            :key="key"
            @click="setTime(time.value)"
          >
            {{ time.text }}
          </v-tab>
        </v-tabs>
        <!-- <v-spacer></v-spacer>
        <div class="select-box-width">
          <location-select-box
            v-model="params.location_id"
            item-text="name"
            item-value="id"
            placeholder="Location"
            dense
            filled
            hide-details
            @change="getData()"
          ></location-select-box>
        </div> -->
      </div>
      <div ref="worshipTable" class="mt-10" :key="tableKey">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          class="rounded-lg"
          :loading="loading"
          :item-class="itemRowBackground"
          :server-items-length="totalItems"
          :options.sync="options"
          @update:options="paginate"
        >
          <template v-slot:[`item.scheduled_at`]="{ item }">
            <span class="text-color-main caption text-no-wrap">
              {{ item.scheduled_at | MIXINS_DATE }}
            </span>
          </template>
          <template v-slot:[`item.album_cover`]="{ item }">
            <v-avatar tile class="ma-2">
              <img :src="item.album_cover" alt="album" />
            </v-avatar>
          </template>
          <template v-slot:[`item.apple_music_url`]="{ item }">
            <span class="text-color-main caption text-no-wrap">
              {{ item.apple_music_url }}
            </span>
          </template>
          <template v-slot:[`item.spotify_url`]="{ item }">
            <span class="text-color-main caption">
              {{ item.spotify_url }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <span class="d-flex">
              <v-btn
                rounded
                small
                depressed
                dark
                color="cyan"
                class="mx-1"
                @click="sendDetails(item, items.indexOf(item))"
              >
                Edit
              </v-btn>
              <delete-item-dialog
                :itemID="item.id"
                :setup="setupDeleteDialog"
                :deleteURL="componentURL"
                :event-name="eventName"
              >
              </delete-item-dialog>
            </span>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import moment from "moment";

const worshipSongsRepository = RepositoryFactory.get("worship-song");

const DeleteItemDialog = () =>
  import("@/components/miscellaneous/dialogs/DeleteItemDialog.vue");

export default {
  components: {
    DeleteItemDialog
  },
  data: () => ({
    selectedRow: -1,
    title: "Worship Songs",
    times: [
      {
        text: "Scheduled",
        value: "scheduled"
      },
      {
        text: "Past",
        value: "past"
      }
    ],
    headers: [
      {
        text: "Scheduled At",
        value: "scheduled_at",
        sortable: false
      },
      {
        text: "Album Cover",
        value: "album_cover",
        sortable: false
      },
      {
        text: "Apple Music URL",
        value: "apple_music_url",
        sortable: false
      },
      {
        text: "Spotify URL",
        value: "spotify_url",
        sortable: false
      },
      {
        text: "Action",
        value: "action",
        sortable: false
      }
    ],
    items: [],
    tableKey: 0,
    loading: false,
    setupDeleteDialog: {
      button: {
        rounded: true,
        small: true,
        depressed: true,
        dark: true,
        class: "mx-1",
        icon: false,
        label: "Delete",
        isIcon: false
      },
      card: {
        title: "Worship Song Delete Item"
      }
    },
    eventName: "reloadItems_worshipSongs",
    componentURL: "api/worship-songs",
    options: {
      page: 1,
      itemsPerPage: 15
    },
    params: {
      page: 1,
      per_page: 15,
      latest: true,
      only_scheduled: true
    },
    totalItems: 0
  }),
  methods: {
    paginate() {
      this.params.page = this.options.page;
      this.params.per_page = this.options.itemsPerPage;

      this.getData();
    },
    getData() {
      this.loading = true;

      let params = { ...this.params };

      worshipSongsRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: data }
          } = response;

          this.totalItems = data.total;

          this.items = data.data.map(worship => {
            worship.scheduled_at = moment(moment.utc(worship.scheduled_at).toDate()).format("YYYY-MM-DD HH:mm:ss");
            return worship;
          });

          console.log(this.items);
        })
        .catch(error => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setTime(value) {
      this.tableKey++;

      this.items = [];

      this.options.page = 1;

      this.params = {
        page: 1,
        per_page: 15,
        latest: true
      };

      if (value === "past") {
        delete this.params.only_scheduled;
        this.params.only_past = true;
      } else {
        delete this.params.only_past;
        this.params.only_scheduled = true;
      }
    },
    sendDetails(item, index) {
      this.selectedRow = index;
      GLOBAL_EVENT_BUS.$emit("showDetails_worshipSong", item);
    },
    reloadItemsEventBus() {
      GLOBAL_EVENT_BUS.$off(this.eventName);
      GLOBAL_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },
    reloadItemsDeleteEventBus() {
      DELETE_ITEM_EVENT_BUS.$off(this.eventName);
      DELETE_ITEM_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },
    resetSelectedEventBus() {
      GLOBAL_EVENT_BUS.$off("resetSelected_worshipSong");
      GLOBAL_EVENT_BUS.$on("resetSelected_worshipSong", () => {
        this.selectedRow = -1;
      });
    },
    setupEventBus() {
      this.reloadItemsEventBus();
      this.reloadItemsDeleteEventBus();
      this.resetSelectedEventBus();
    },
    itemRowBackground(item) {
      return this.selectedRow == this.items.indexOf(item)
        ? "active-table-row"
        : "";
    }
  },
  mounted() {
    // this.getData();
    this.setupEventBus();
  }
};
</script>
